
import React,{useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import {useAuthDataContext} from "./AuthDataProvider";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { api } from "../Utils/ApiUrl";


function Order(){
    let navigate = useNavigate();
   const { tappedOrderArr,
     setTappedOrderArr,
    tappedMedicineArr,
     setTappedMedicineArr,
tappedCustomerArr,setTappedOrderId,
  setTappedCustomerArr ,iDtoken }= useAuthDataContext();


    const [orderList, setOrderList] = useState([]);

    const [pageCount, setpageCount] = useState(0);
   
    //the _id of order the user tapped on
    const [orderId, setOrderId]= useState("");

 


    useEffect(() => {

       var data= {
            status:"Pending",
            prescriptionImageFlag:false
        }

         const config = {
            headers: { Authorization: `Bearer ${iDtoken}` }
        };
       
        axios
            .post(api.baseUrl+"api/get-all-orders",data,config)
            .then((response) => {
                
                setOrderList(response.data.result);
                
            })

      }, []);


      const handlePageClick = async (data) => {
        console.log(data.selected);
    
        let currentPage = data.selected + 1;
        setpageCount(currentPage);
    
      };


      const handleDetailClick = (id) => {

        setTappedOrderId(id);
        const config = {
            headers: { Authorization: `Bearer ${iDtoken}` }
        };

        
        axios
        .get(`${api.baseUrl}api/get-order-by-id/${id}`,config)
        .then((response) => {
    
            // console.log(response.data.result);

            const config = {
                headers: { Authorization: `Bearer ${iDtoken}` }
            };

           

            axios
        .get(`${api.baseUrl}api/get-customer-by-id/${response.data.result[0].customerId}`,config)
        .then((response2) => { 

            setTappedCustomerArr(response2.data.result);
            setTappedOrderArr(response.data.result[0]);
        //    JSON.stringify(response.data.result[0].medicineArr)
            setTappedMedicineArr(response.data.result[0].medicineArr);
            
            setOrderId(id);
            navigate("/orderDetail");
       
        }).catch((error) => { console.log(error + " Error in Order.js get-order-by-id axios call")})
          
           
    
    
            
        }).catch((error) => { console.log(error + " Error in Order.js get-order-by-id axios call")})
      }

    

    return(
        <>
        <div class="breadcome-area">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="breadcome-list">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div class="breadcomb-wp">
                                        <div class="breadcomb-icon">
                                            <i class="icon nalika-home"></i>
                                        </div>
                                        <div class="breadcomb-ctn">
                                            <h2>Order List</h2>
                                            <p>Welcome to Glizmed <span class="bread-ntd">Admin Panel</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div class="breadcomb-report">
                                        <button data-toggle="tooltip" data-placement="left" title="Download Report" class="btn"><i class="icon nalika-download"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid">
                <div className="maindiv1">
                   <div class="product-status-wrap tab-order-list">
                       <h4>Order Table</h4>
                      <table >
                           <tr >
                               <th>Date</th>
                               <th>Time</th>
                               <th>Phone Number</th>
                               <th>Status</th>
                               <th>Details </th>
                           </tr>
                           {orderList.map(item => (
                            <tr>
                               <td>{item.date}/{item.month}/{item.year}</td>
                               <td>{item.time}</td>
                               <td>{item.phoneNumber}</td>
                               <td>{item.status}</td>
                               <td> <button onClick={()=>{handleDetailClick(item._id)}}> Get Details</button></td>
                            </tr>
       
                           ))}
       
                       </table>
                     
                       <ReactPaginate className="pagination"
                           previousLabel={"previous"}
                           nextLabel={"next"}
                           breakLabel={"..."}
                           pageCount={pageCount}
                           marginPagesDisplayed={2}
                           pageRangeDisplayed={3}
                           onPageChange={handlePageClick}
                           containerClassName={"pagination justify-content-center"}
                           pageClassName={"page-item"}
                           pageLinkClassName={"page-item"}
                           previousClassName={"page-item"}
                           previousLinkClassName={"page-item"}
                           nextClassName={"page-item"}
                           nextLinkClassName={"page-item"}
                           breakClassName={"page-item"}
                           breakLinkClassName={"page-item"}
                           activeClassName={"activepagination"}
                       /> 
                       
                   </div>
                </div>
            </div>
        </div>
                 
                   
                 
                   </>
                
     )

}
export default Order;