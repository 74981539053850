import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { useAuthDataContext } from "./AuthDataProvider"
import OrderStatusModal from "../Utils/OrderStatusModal";
import axios from "axios";
import { api } from "../Utils/ApiUrl";


function Prescription() {

    let navigate = useNavigate();
    const { setTappedPrescriptionId,setTappedPrescriptionCustomerArr ,
       setTappedPrescriptionArr,
       setTappedPrescriptionImageArray ,iDtoken}= useAuthDataContext();

    const [items, setItems] = useState([]);
    const [prescriptionList, setPrescriptionList] = useState([]);




const handleDetailClick = (id) => {
  const config = {
            headers: { Authorization: `Bearer ${iDtoken}` }
        };
     
    setTappedPrescriptionId(id);

  
    

    axios
    .get(`${api.baseUrl}api/get-prescription-order-by-id/${id}`,config)
    .then((response) => {
        
       
    axios
    .get(`${api.baseUrl}api/get-customer-by-id/${response.data.result[0].customerId}`,config)
    .then((response2) => { 

        setTappedPrescriptionCustomerArr(response2.data.result);
        //The whole prescription array
        setTappedPrescriptionArr(response.data.result);
//the prescriptionImage Array field inside this particular prescription document
let prescriptionImageTempArr= [];
response.data.result[0].prescriptionImage.forEach(element => {
    prescriptionImageTempArr.push(element)
});


setTappedPrescriptionImageArray(prescriptionImageTempArr);

      
     navigate("/prescriptionDetail");
   
    }).catch((error) => { console.log(error + " Error in Order.js get-customer-by-id axios call")})
    
}).catch((error) => { console.log(error + " Error in Order.js get-prescription-order-by-id axios call")})
 
  }

      
    useEffect(() => {

        var data= {
             status:"Pending",
        }
        const config = {
            headers: { Authorization: `Bearer ${iDtoken}` }
        };
       
         axios
             .post(api.baseUrl+"api/get-all-prescription-orders",data,config)
             .then((response) => {
                 
               
                setPrescriptionList(response.data.result);
               
             })
 
        
       }, []);
 

    return (
        <>
            <div class="breadcome-area" >
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="breadcome-list">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div class="breadcomb-wp">
                                            <div class="breadcomb-icon">
                                                <i class="icon nalika-home"></i>
                                            </div>
                                            <div class="breadcomb-ctn">
                                                <h2>Order List</h2>
                                                <p>Welcome to Glizmed <span class="bread-ntd">Admin Panel</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div class="breadcomb-report">
                                            <button data-toggle="tooltip" data-placement="left" title="Download Report" class="btn"><i class="icon nalika-download"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="maindiv">
                        <div class="product-status-wrap tab-order-list">
                            <h4>Prescription Table</h4>

                            <table >
                            <tr >
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th >Phone Number</th>
                                        <th >Status</th>
                                        <th> Details </th>
                                    </tr>
                                    {prescriptionList.map(item => (
                                        <tr>
                                        <td>{item.date}/{item.month}/{item.year}</td>
                                        <td>{item.time}</td>
                                        <td>{item.phoneNumber}</td>
                                        <td>{item.status}</td>
                                        <td> <button onClick={()=>{handleDetailClick(item._id)}}> Get Details</button></td>
                                        </tr>
                
                                    ))}


                            </table>
                        
                            {/* <ReactPaginate className="pagination"
                                previousLabel={"previous"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-item"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-item"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-item"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-item"}
                                activeClassName={"activepagination"}
                            />  */}
                            

                            {/* <input type="file" onChange={onImageChange} />
                <img src={img} alt="" /> */}



                
                        </div>
                    </div>
                </div>

            </div>

        </>

    )

}
export default Prescription;