import "../App.css";

import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import {  useNavigate } from "react-router-dom";
import FormModal from "../Utils/FormModal";
import {useAuthDataContext} from "./AuthDataProvider";
import { api } from "../Utils/ApiUrl";

function AddBrand(props) {
    let navigate = useNavigate();
    const { iDtoken }= useAuthDataContext();
    const [brandName, setBrandName] = useState("");
    const [fileOne, setFileOne] = useState("");
    const [showaddBrandModal, setShowAddBrandModal] = useState(false);
    const [formStatus, setFormStatus] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(brandName)
        const data = new FormData();

        data.append("brandName", brandName);
       
    
    
        for (let i = 0; i < fileOne.length; i++) {
          data.append("fileOne", fileOne[i]);
        }
      
        const config = {
            headers: { Authorization: `Bearer ${iDtoken}` }
        };
     
        axios
            .post(api.baseUrl+"api/create-brandName",data,config)
            .then((response) => {

               console.log(response)
               if(
                response.data.status === true
            ){
              setFormStatus(true)
               
            }else{
                setFormStatus(false)
            
            }
                
            setShowAddBrandModal(true);


    })
            }
            const changeBrandName = (e) => {


                setBrandName(e.target.value);
            }
    


    // useEffect(() => {



    // }, []);

    return (
        <>
            <div class="breadcome-area">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="breadcome-list">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div class="breadcomb-wp">
                                            <div class="breadcomb-icon">
                                                <i class="icon nalika-home"></i>
                                            </div>
                                            <div class="breadcomb-ctn">
                                                <h2>Add Brand</h2>
                                                <p>Welcome to Glizmed <span class="bread-ntd">Admin Panel</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div class="breadcomb-report">
                                            <button data-toggle="tooltip" data-placement="left" title="Download Report" class="btn"><i class="icon nalika-download"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                  
                <div class="container-fluid">
                    <div className="maindiv1">
                        <div class="product-status-wrap">
                            <form onSubmit={handleSubmit} className="form-bg p-2 py-4 submit-small-btn">
                                <div className="MedicineMain">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <label className="LabelName">Brand Name</label>
                                            <input type="text" name="brandName" placeholder="BrandName" defaultValue={brandName}  className="medicineAddFormIpTag" onChange={   changeBrandName }/>

                                        </div>
                                        <div className="col-lg-6">
                                            <label className="uploadprs">Upload File <label htmlFor="file1" className="uploadnow" > Choose image <i class="fa-solid fa-arrow-right"></i>
                                                <input
                                                style={{ display: "none", visibility: "none" }}
                                                type="file"
                                                id="file1"
                                                name="fileOne"
                                                multiple
                                                onChange={(e) => {
                                                    setFileOne(e.target.files);
                                                    // setErrorFlag(false);
                                                }}
                                                />

                                                
                                                </label>
                                            </label>
                                        </div>
                                        <div className="col-lg-12 mt-3">
                                            <input type="submit" name="" value="Submit" />
                                        </div>           
                                        

                                    </div>
                                </div>
                            </form>
                        
                        </div>
                    </div>
                </div>          
          
            </div>

          
            <FormModal show={showaddBrandModal} 
setShow={setShowAddBrandModal} 
formStatus={formStatus}
navigate={navigate} path="addBrand" />

            
            
        </>

    )
        ;
}

export default AddBrand;
