// import axios from "axios";
// import { useEffect } from "react";
// import { useAuthDataContext } from "./AuthDataProvider";


function Detail(props) {

  




    return (
        <div className="">

            Detail Page <br />



        </div>
    );
}

export default Detail;
