import "../App.css";
import "../components/Css/metisMenu.min.css";
import '../components/Css/metisMenu-vertical.css';
import '../components/Css/nalika-icon.css';
import '../components/Css/responsive.css';
import '../components/Css/meanmenu.min.css';
import Dashboard from "./Dashboard";
import Medicinelist from "./Medicinelist";
import { useAuthDataContext } from "./AuthDataProvider"
import { Outlet, Link, useNavigate } from "react-router-dom";
import React from "react";
import { useState, useEffect } from "react";

import userimg from '../images/user.png'
import { getIdToken, onAuthStateChanged ,getIdTokenResult , signOut} from "firebase/auth";
import {authentication} from "../services/firebase";

import userEvent from "@testing-library/user-event";

import glizmedLogo from '../images/glizmed-logo.png'

const PrivateRoute = () => {
  let navigate = useNavigate();
  const { iDtoken,setIdToken }= useAuthDataContext();

  useEffect(() => {
   
    onAuthStateChanged(authentication, async (user) => {
      if (user) {
        const token = await getIdToken(user);
   console.log(token);
   if(token){
    setIdToken(token);
    const tokenRes = await getIdTokenResult(user);
   } else {

    signOut(authentication).then(r => {console.log(r );navigate("/login"); }) 
.catch();
   }
       
       
      
      } 
    });

  }, []);

  const logOutHandle = ()=> {
    signOut(authentication).then(r => {console.log(r );navigate("/login"); }) 
    .catch();
  }

  return (
    <>
      <div class="header-advance-area">
        <div class="header-top-area">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="header-top-wraper">
                  <div class="row">
                    <div class="col-lg-1 col-md-0 col-sm-1 col-xs-12">
                      <div class="menu-switcher-pro">
                        <button type="button" id="sidebarCollapse"
                          class="btn bar-button-pro header-drl-controller-btn btn-info navbar-btn">
                          <i class="fa-solid fa-bars"></i>
                        </button>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-7 col-sm-6 col-xs-12">
                      <div class="header-top-menu tabl-d-n hd-search-rp">
                        <div class="breadcome-heading">
                          <form role="search" class="">
                            <input type="text" placeholder="Search..." class="form-control" />
                            <a href=""><i class="fa fa-search"></i></a>
                          </form>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12">
                     


                    <ul class="dtl-social">
                      <li style={{color:"white"}} onClick={logOutHandle}>Log out</li>

                      <li><a href="#"><i class="fa-solid fa-envelope"></i></a></li>
                      <li><a href="#"><i class="fa-solid fa-user"></i></a></li>
                  
                      </ul>
                      </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>




      </div>
      <div class="left-sidebar-pro">
        <nav id="sidebar" class="">
          <div class="sidebar-header">
            {/* <a href="index.html"><img class="main-logo" src={userimg} alt="" /></a> */}
            {/* <strong><img src={userimg} alt="" /></strong> */}

            {/* <div class="nalika-profile">
              <div class="profile-dtl">
                <a href="#"><img src={userimg} alt="" /></a>
                <h2>Lakian <span class="min-dtn">Das</span></h2>
              </div>
              <div class="profile-social-dtl">
                <ul class="dtl-social">
                  <li><a href="#"><i class="fa-brands fa-facebook"></i></a></li>
                  <li><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
                  <li><a href="#"><i class="fa-brands fa-linkedin"></i></a></li>
                </ul>
              </div>
            </div> */}
            <div class="left-custom-menu-adp-wrap comment-scrollbar">
              <nav class="sidebar-nav left-sidebar-menu-pro">
                <div className="site-logo">
                  <img src={glizmedLogo} alt="" />
                </div>
                <ul class="metismenu" id="menu1">
                  <li class="active">

                    {/* <a title="Dashboard" href="index.html"><span class="mini-sub-pro">Dashboard</span></a> */}

                    <Link to="/dashboard" className="sidebarLink">
                      Dashboard
                    </Link>

                  </li>

                  {/* <li>
                    <a title="Order" href="order.html"><span class="mini-sub-pro">Orders</span></a>
                  </li> */}
                  <Link to="/order" className="sidebarLink">
                    Order
                  </Link>

                  <li>
                    <Link to="/approvedOrder" className="sidebarLink">
                      Approved Order
                    </Link>
                  </li>
                  <li>
                    <Link to="/prescription" className="sidebarLink">
                      Prescription Order
                    </Link>
                  </li>
                  <li>
                    <Link to="/approvedPres" className="sidebarLink">
                      Approved Prescription
                    </Link>
                  </li>
                  <li>
                    <a class="has-arrow" href="#" aria-expanded="false"><i
                      class="icon nalika-mail icon-wrap"></i> <span
                        class="mini-click-non">Medicine</span></a>

                    <ul class="submenu-angle" aria-expanded="false">
                      <li>

                        <Link to="/medicineList" className="sidebarLink">
                          Medicinelist
                        </Link>

                      </li>

                      <li>

                        <Link to="/medicineAdd" className="sidebarLink">
                          MedicineAdd
                        </Link>

                      </li>
                      <li>

                    

                      </li>

                    </ul>
                  </li>

                  {/* <li>
                  <Link to="/test" className="sidebarLink">
                          test
                        </Link>
                  </li> */}
                  <li>
                    <a class="has-arrow" href="#" aria-expanded="false"><i
                      class="icon nalika-mail icon-wrap"></i> <span
                        class="mini-click-non">Category</span></a>
                    <ul class="submenu-angle" aria-expanded="false">
                      
                      {/* <li><a title="Category List" href="category-list.html"><span
                        class="mini-sub-pro">Category List</span></a></li> */}
                      
                            <Link to="/addCategory" className="sidebarLink">
                        AddCategory
                        </Link>
                        <Link to="/categoryList" className="sidebarLink">
                      Category List
                        </Link>
                        <Link to="/addSubCategory" className="sidebarLink">
                        AddSubCategory
                        </Link>
                        <Link to="/subCategoryList" className="sidebarLink">
                        SubCategoryList
                        </Link>
                    </ul>
                    
                  </li>
                  <li>
                    <a class="has-arrow" href="#" aria-expanded="false"><i
                      class="icon nalika-mail icon-wrap"></i> <span
                        class="mini-click-non">Brand</span></a>
                    <ul class="submenu-angle" aria-expanded="false">
                      {/* <li><a title="Add Brand" href="add-brand.html"><span class="mini-sub-pro">Add
                        Brand</span></a></li> */}
                        <Link to="/addBrand" className="sidebarLink">
                    Add Brand
                        </Link>
                        <Link to="/brandList" className="sidebarLink">
                    Brand List
                        </Link>
                      {/* <li><a title="Brand List" href="brand-list.html"><span class="mini-sub-pro">Brand
                        List</span></a></li> */}

                    </ul>
                  </li>



                </ul>
              </nav>
            </div>
          </div>
          
        </nav>
      </div>

      <Outlet />
    </>
  );
};

export default PrivateRoute;
