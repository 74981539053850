import { useEffect } from "react";

import React, { useState } from "react";
 import {authentication} from "../../services/firebase";
 import { RecaptchaVerifier ,signInWithPhoneNumber } from "firebase/auth";
 import SendOTP from "./SendOTP";
 import VerifyOTP from "./VerifyOTP"
 import Success from "./Success";
 import { useNavigate } from "react-router-dom";
 import axios from "axios";
//  import {fnGetCurrentTime , fnGetCurrentDate} from "../../Utils/CalculateTime";
import { useAuthDataContext } from "../AuthDataProvider";

function Login() {
  let navigate = useNavigate();
    const [phoneNumber,setPhoneNumber ] = useState();
    const [otp,setOtp ] = useState();
    const [componentShown , setComponentShown] =useState("sendOtp");


    const { setIdToken }= useAuthDataContext();

    useEffect(() => {


     
     }, []);

const fnSetPhoneNumberHandle = (e) => {
console.log(e.target.value);
setPhoneNumber("+91"+e.target.value);
}

    const generateRecaptcha = () => {
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
          
            }
          }, authentication);
    }
    const sendOTP = () => {
        generateRecaptcha(); 
      
        let appVerifier =window.recaptchaVerifier;
        signInWithPhoneNumber(authentication, phoneNumber, appVerifier)
    .then((confirmationResult) => {

      
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      window.confirmationResult = confirmationResult;
      setComponentShown("verifyOtp");
      
      // ...
    }).catch((error) => {
      // Error; SMS not sent
      // ...
    });
    }

    const verifyOTP = ()=> {
        
       
        let confirmationResult= window.confirmationResult;
        confirmationResult.confirm(otp).then((result) => {
            // User signed in successfully.
            const user = result.user;
       
            localStorage.setItem("uidDashboard", user.uid);
            localStorage.setItem("accessTokenDashboard", user.stsTokenManager.refreshToken);
            localStorage.setItem("expirationTimeDashboard", user.stsTokenManager.expirationTime);
            localStorage.setItem("lastLoginAtDashboard", user.lastLoginAt);
            localStorage.setItem("createdAtDashboard", user.createdAt);
            localStorage.setItem("loginFlagDashboard","true");
            localStorage.setItem("phoneNumberDashboard",phoneNumber);


            navigate("/dashboard");
        
     })
    


    }

if(componentShown === "sendOtp") {
  return (
    <>
    <SendOTP setPhoneNumber={(e)=> {fnSetPhoneNumberHandle(e)}} sendOTP={sendOTP} />
    </>
  );
} else if(componentShown === "verifyOtp") {
  return (
    <>

<VerifyOTP setOtp={setOtp} verifyOTP={verifyOTP} sendOTP={sendOTP}/>
    </>
   );
} else {
  return (
    <>
<Success />
</>
  );
}
  
}
 
export default Login;