import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import Dashboard from "./Dashboard";
import Checkout from "./Checkout";

import Order from './Order';
import Prescription from "./Prescription";
import Medicinelist from "./Medicinelist";

import MedicineAdd from "./MedicineAdd";



import PrivateRoute from "./PrivateRoute";
import Error from "./Error";
import Detail from "./Detail";
import Test from "./Test";
import AddCategory from "./AddCategory";
import AddSubCategory from "./AddSubCategory";
import SubCategoryList from "./SubCategoryList";
import CategoryList from "./CategoryList";
import AddBrand from "./AddBrand";
import BrandNameList from "./BrandNameList";

import UpdateCategory from "./UpdateCategory";
import UpdateSubCategory from "./UpdateSubcategory";


import OrderDetail from "./OrderDetail";
import ApprovedOrder from "./ApprovedOrder";
import PrescriptionDetail from "./PrescriptionDetail";

import ApprovedPrescription from "./ApprovedPrescription";
import UpdateBrandName from "./UpdateBrandName";
import UpdateMedicine from "./UpdateMedicine";

import Login from "./LoginSignUp/Login";


const Router = () => {
 

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/aboutus" element={<Login />} />
        <Route path="/" element={<PrivateRoute />}  >
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="detail" element={<Detail />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path='order' element={<Order/>}/>
          <Route path ='prescription'element={<Prescription/>}/>
          <Route path='medicineList' element={<Medicinelist/>}/>
          <Route path='addCategory' element={<AddCategory/>}/>
          <Route path='medicineAdd' element={<MedicineAdd/>}/>
              
          <Route path='addSubCategory' element={<AddSubCategory/>}/>
          <Route path='subCategoryList' element={<SubCategoryList/>}/>
          <Route path='categoryList' element={<CategoryList/>}/>
          <Route path='addBrand' element={<AddBrand/>}/>
          <Route path='brandList' element={<BrandNameList/>}/>
          <Route path='approvedOrder' element={<ApprovedOrder/>}/>
          <Route path='test' element={<Test/>}/>


          <Route path='updateCategory' element={<UpdateCategory/>}/>
          <Route path='updateSubCategory' element={<UpdateSubCategory/>}/>
          <Route path='updateBrandName' element={<UpdateBrandName/>}/>
          <Route path='updateMedicine' element={<UpdateMedicine/>}/>
          <Route path="orderDetail" element={<OrderDetail />} />
          <Route path="prescriptionDetail" element={<PrescriptionDetail />} />

          <Route path="approvedPres" element={<ApprovedPrescription />} />
          

        </Route>
        <Route path="*" element={<Error />} />
      </Routes>
    </>
  );
};

export default Router;
