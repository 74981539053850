import React, { createContext, useState, useContext, useEffect } from "react";


export const AuthDataContext = createContext(null);

const AuthDataProvider = (props) => {

  const [authVariable, setAuthVariable] = useState(false);
  const [updateCategoryId, setUpdateCategoryId] = useState();
  const [updateSubCategoryId, setUpdateSubCategoryId] = useState();
  const [updateBrandNameId, setUpdateBrandNameId] = useState();
  const [updateMedicineId, setUpdateMedicineId] = useState();





 
const [tappedOrderId, setTappedOrderId] = useState();
const [tappedOrderArr, setTappedOrderArr] = useState([]);
const [tappedMedicineArr, setTappedMedicineArr] = useState([]);
const [tappedCustomerArr, setTappedCustomerArr] = useState([]);


const [tappedPrescriptionId, setTappedPrescriptionId] = useState();
const [tappedPrescriptionArr, setTappedPrescriptionArr] = useState([]);

const [tappedPrescriptionCustomerArr, setTappedPrescriptionCustomerArr] = useState([]);
const [tappedPrescriptionImageArray, setTappedPrescriptionImageArray] = useState([]);

const [iDtoken, setIdToken] = useState("");

const handleTappedMedicineArrRemoveItem = (index)=>{
 
    const list = [...tappedMedicineArr];
list.splice(index, 1);
setTappedMedicineArr(list);

};

const handleTappedPrescriptionArrRemoveItem = (index)=> {
  const list = [...tappedPrescriptionImageArray];
  list.splice(index, 1);
  setTappedPrescriptionImageArray(list);
}

 


  


 
  

  const ContextValue = {
    authVariable: authVariable,
    setAuthVariable: setAuthVariable,
   
    tappedOrderArr: tappedOrderArr,
     setTappedOrderArr: setTappedOrderArr,
    tappedMedicineArr: tappedMedicineArr,
     setTappedMedicineArr: setTappedMedicineArr,
tappedCustomerArr :tappedCustomerArr,
 setTappedCustomerArr: setTappedCustomerArr,
 handleTappedMedicineArrRemoveItem:handleTappedMedicineArrRemoveItem ,
 tappedOrderId:tappedOrderId,
  setTappedOrderId: setTappedOrderId, 

 tappedPrescriptionId :tappedPrescriptionId,
  setTappedPrescriptionId : setTappedPrescriptionId,
  tappedPrescriptionArr:tappedPrescriptionArr,
 setTappedPrescriptionArr:setTappedPrescriptionArr,
tappedPrescriptionCustomerArr:tappedPrescriptionCustomerArr, 
setTappedPrescriptionCustomerArr:setTappedPrescriptionCustomerArr,

handleTappedPrescriptionArrRemoveItem:handleTappedPrescriptionArrRemoveItem,
tappedPrescriptionImageArray:tappedPrescriptionImageArray,
setTappedPrescriptionImageArray:setTappedPrescriptionImageArray,

updateCategoryId: updateCategoryId,

 

 iDtoken:iDtoken,
 setIdToken:setIdToken,

setUpdateCategoryId: setUpdateCategoryId,

updateSubCategoryId:updateSubCategoryId,
setUpdateSubcategoryId:setUpdateSubCategoryId,

updateBrandNameId:updateBrandNameId,
setUpdateBrandNameId:setUpdateBrandNameId,

updateMedicineId:updateMedicineId,
setUpdateMedicineId:setUpdateMedicineId,






  };

  return <AuthDataContext.Provider value={ContextValue} {...props} />;
};

export const useAuthDataContext = () => useContext(AuthDataContext);

export default AuthDataProvider;
