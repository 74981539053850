import React from "react";
import Modal from "react-bootstrap/Modal";


//PROFILE UPDATED MODAL
export default function PresOrderStatusModal(props) {
  const handleClose = () => {
    props.setShow(false);
   
    props.navigate("/approvedOrder")

   

  };
  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        style={{
          top: "20%",
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.uploadPresMessage}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            {props.orderStatus === true ? <span>
              Approved successfully
            </span> : <span>
              Order Rejected !
            </span>}
            {/* <img src={logo}  alt="document" className="glizmedLogoProfileUpdateModal"/ > <br /><br /> */}
            <button className="updatedProfileBtn" onClick={handleClose}> OK </button>
            

          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
