import React from "react";
import Modal from "react-bootstrap/Modal";


//PROFILE UPDATED MODAL
export default function FormModal(props) {
  const handleClose = () => {
    props.setShow(false);
    if (props.path === "medicineAdd") { props.navigate("/medicineList") }
    else if(
      props.path === "AddSubCategory"
    ){props.navigate("/subCategoryList")}

    else if(
      props.path === "addCategory"
    ){props.navigate("/categoryList")}
    else if(
      props.path === "addBrand"
    ){props.navigate("/brandList")}
    else {
      props.navigate("/medicineList")
    }

  };
  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        style={{
          top: "20%",
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.uploadPresMessage}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            {props.formStatus === true ? <span>
              Added Succesfully
            </span> : <span>
              There seems to be an error ! please try again Later
            </span>}
            {/* <img src={logo}  alt="document" className="glizmedLogoProfileUpdateModal"/ > <br /><br /> */}
            <button className="updatedProfileBtn" onClick={handleClose}> OK </button>
            

          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
