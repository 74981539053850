import React from "react";
import Modal from "react-bootstrap/Modal";
import approvedIcon from '../images/glizmed-logo.png'

//PROFILE UPDATED MODAL
export default function OrderStatusModal(props) {
  const handleClose = () => {
    props.setShow(false);
   
    props.navigate("/approvedOrder")

   

  };
  return (
    <div>
      <Modal
        className="approved-modal"
        show={props.show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.uploadPresMessage}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center d-flex flex-direction-column align-items-center">
            <img src={approvedIcon} alt="" />
            {props.orderStatus === true ? <span>
              Approved successfully
            </span> : <span>
              Order Rejected !
            </span>}
            {/* <img src={logo}  alt="document" className="glizmedLogoProfileUpdateModal"/ > <br /><br /> */}
            <button className="updatedProfileBtn" onClick={handleClose}> OK </button>
            

          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
