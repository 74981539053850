import React, { useState ,useEffect} from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuthDataContext } from "./AuthDataProvider";
import "../App.css";
import "../components/Css/metisMenu.min.css";
import '../components/Css/metisMenu-vertical.css';
import '../components/Css/nalika-icon.css';
import '../components/Css/responsive.css';
import '../components/Css/meanmenu.min.css';

function Dashboard() {
    





return(
   <div class="breadcome-area">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="breadcome-list">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div class="breadcomb-wp">
											<div class="breadcomb-icon">
												<i class="icon nalika-home"></i>
											</div>
											<div class="breadcomb-ctn">
												<h2>Dashboard</h2>
												<p>Welcome to Glizmed <span class="bread-ntd">Admin Panel</span></p>
											</div>
										</div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div class="breadcomb-report">
											<button data-toggle="tooltip" data-placement="left" title="Download Report" class="btn"><i class="icon nalika-download"></i></button>
										</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
               
            </div>
           
)

}

export default Dashboard; 
