import "../App.css";

import React from "react";

function Checkout(props) {
  console.log("test component console");
  return (
    <div className="">
      checkoutttt
   
    </div>
  );
}

export default React.memo(Checkout);
