import "../App.css";

import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import {  useNavigate } from "react-router-dom";
import FormModal from "../Utils/FormModal";
import {useAuthDataContext} from "./AuthDataProvider";
import { api } from "../Utils/ApiUrl";

function AddSubCategory(props) {
    let navigate = useNavigate();

  
const { iDtoken }= useAuthDataContext();

    const [Categorylist, setCategorylist] = useState([]);
    const [fileOne, setFileOne] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [SubCategoryName, setSubCategoryName] = useState("");
    const [showSubCategoryAddModal, setShowSubCategoryAddModal] = useState(false);
    const [formStatus, setFormStatus] = useState("");
    const [SubCategory, setSubCategory] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(SubCategoryName)
        const data = new FormData();

        data.append("subCategoryName", SubCategoryName);
        data.append("categoryId",selectedCategory)


        for (let i = 0; i < fileOne.length; i++) {
            data.append("fileOne", fileOne[i]);
        }
        const config = {
            headers: { Authorization: `Bearer ${iDtoken}` }
        };

        axios
            .post(api.baseUrl+"api/create-subcategory-dashboard", data,config)
            .then((response) => {

                console.log(response)

                
if(
    response.data.status === true
){
  setFormStatus(true)
   
}else{
    setFormStatus(false)

}
    
setShowSubCategoryAddModal(true);
             

            })
    }


    const changeSubCategoryName = (e) => {
        setSubCategoryName(e.target.value);



    }
    const handleSelectedCategory = (e) => {
        console.log(e.target.value)
        setSelectedCategory(e.target.value);


    }
    const changeSubCategory = (e) => {
        setSubCategory(e.target.value);


    }


    useEffect(() => {

        const config = {
            headers: { Authorization: `Bearer ${iDtoken}` }
        };

        
        axios
            .get(api.baseUrl+"api/get-all-Category",config)
            .then((response) => {

                setCategorylist(response.data.result);
                console.log(response + " sdfgdhjgh")
            })

    }, []);

    return (
        <>
            <div class="breadcome-area">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="breadcome-list">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div class="breadcomb-wp">
                                            <div class="breadcomb-icon">
                                                <i class="icon nalika-home"></i>
                                            </div>
                                            <div class="breadcomb-ctn">
                                                <h2>Add SubCategory</h2>
                                                <p>Welcome to Glizmed <span class="bread-ntd">Admin Panel</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div class="breadcomb-report">
                                            <button data-toggle="tooltip" data-placement="left" title="Download Report" class="btn"><i class="icon nalika-download"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-fluid">
                    <div className="maindiv1">
                        <div class="product-status-wrap">
                            <form onSubmit={handleSubmit} className="form-bg p-2 py-4 submit-small-btn">
                                {/* <div className="MedicineMain col-lg-12"> */}
                                <div className="row">
                                    <div className="col-lg-6">
                                        <label className="LabelName">Choose Category</label>
                                        <select className="form-control" placeholder="categorylist"
                                                onChange={(e) => {
                                                    changeSubCategory(e);
                                                }}
                                                // value={Categorylist}
                                            >
                                                <option key={"chooseCategory"}>{"--Choose Category--"}</option>

                                                {Categorylist.map((value,i) => {
                                                    return (
                                                        <option key={i} value={value._id} >
                                                        {value.categoryName}
                                                            
                                                        </option>
                                                    );
                                                })}
                                            </select>


                                    </div>

                                    <div className="col-lg-6">
                                        <label className="LabelName">Sub Category Name</label>
                                        <input type="text" name="subCategoryName" placeholder="CategoryName" defaultValue={SubCategoryName} className="medicineAddFormIpTag" onChange={changeSubCategoryName} />

                                    </div>
                                    <div className="col-lg-6">
                                        <label className="uploadfile">
                                            Upload File
                                            <label htmlFor="file1" className="uploadnow" > Choose image <i class="fa-solid fa-arrow-right"></i>
                                                <input
                                                    style={{ display: "none", visibility: "none" }}
                                                    type="file"
                                                    id="file1"
                                                    name="fileOne"
                                                    multiple
                                                    onChange={(e) => {
                                                        setFileOne(e.target.files);
                                                        // setErrorFlag(false);
                                                    }}
                                                />
                                            </label>
                                        </label>

                                    </div>
                                </div>
                                <input type="submit" name="" value="Submit" />
                                
                                {/* </div> */}
                            </form>
                        </div>
                    </div>
                </div>

            </div>

            <FormModal show={showSubCategoryAddModal} 
setShow={setShowSubCategoryAddModal} 
formStatus={formStatus}
navigate={navigate} path="AddSubCategory" />


        </>

    )
        ;
}

export default AddSubCategory;
