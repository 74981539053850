import "../../App.css"

import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
function Success(props) {
    let navigate = useNavigate();
    useEffect(() => {
  
        setTimeout(navigate("/dashboard"), 3000);
       
       }, []);
  return <div>
    	<section className="signin-main">
		<div className="container">
			<div className="row">
				<div className="col-lg-12 text-center justify-content-center">
					<div className="account-inner">
						<div className="card">
							<div className="Success-icon">
								<i className="checkmark">✓</i>
							</div>
							<h1>Login Successfully</h1> 
							<p>Thanks for Choosing us<br/> we'll be in touch shortly!</p>
						</div>
                    </div>
				</div>
			</div>
		</div>
	</section>
  </div>;
}

export default Success;
