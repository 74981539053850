import React from "react";
import Modal from "react-bootstrap/Modal";

export default function DeleteConfirmationModal(props) {

  const handleClose = () => props.setShow(false);

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        style={{
          top: "20%",
        }}
      >
        <Modal.Header >
          <Modal.Title>Are you sure you want to Delete ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="deleteModal">
         
         
            <div className="btnModalParent">
              <div className="">
            <button className="" onClick={()=>{props.setShow(false)}}> Cancel</button>
            </div>
            <div  className="">
            <button className="btn-delete" onClick={()=> {props.setDeleteRowStatus(true)}}> Delete</button>
            </div>
            </div>
          
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}