import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

import { useAuthDataContext } from "./AuthDataProvider";
import FormModal from "../Utils/FormModal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { api } from "../Utils/ApiUrl";



function MedicineAdd() {
    let navigate = useNavigate();
    // const [medicinelist, setMedicinelist] = useState([]);

    
    const { iDtoken }= useAuthDataContext();

    const [showMedicineAddModal, setShowMedicineAddModal] = useState(false);
    const [ProductId, setProductId] = useState("");
    const [ProductName, setProductName] = useState("");
    const [MRP, setMRP] = useState("");
    const [Discount, setDiscount] = useState("");
    const [SellingPrice, setSellingPrice] = useState("");
    const [DeliveryTime, setDeliveryTime] = useState("");
    const [PackingType, setPackingType] = useState("");
    const [Packaging, setPackaging] = useState("");
    const [Compositions, setCompositions] = useState("");
    const [Usage, setUsage] = useState("");
    const [NameOfManufacturer, setNameOfManufacturer] = useState("");
    const [DrugSchedule, setDrugSchedule] = useState("");
    const [AboutSalt, setAboutSalt] = useState("");
    const [MechanismOfAction, setMechanismOfAction] = useState("");
    const [Pharmacokinetics, setPharmacokinetics] = useState("");
    const [DurationOfAction, setDurationOfAction] = useState("");
    const [OnsetOfActions, setOnsetOfActions] = useState("");
    const [HalfLife, setHalfLife] = useState("");
    const [SideEffects, setSideEffects] = useState("");
    const [ContraIndications, setContraIndications] = useState("");
    const [PrecautionsPregnancyRelatedInformation, setPrecautionsPregnancyRelatedInformation] = useState("");
    const [ProductAndAlcoholInteraction, setProductAndAlcoholInteraction] = useState("");
    const [OldAgeRelatedInformation, setOldAgeRelatedInformation] = useState("");
    const [BreastfeedingRelatedInformation, setBreastfeedingRelatedInformation] = useState("");
    const [ChildrenRelatedInformation, setChildrenRelatedInformation] = useState("");
    const [Indications, setIndications] = useState("");
    const [Interactions, setInteractions] = useState("");
    const [TypicalDosage, setTypicalDosage] = useState("");
    const [StorageRequirements, setStorageRequirements] = useState("");
    const [EffectsOfMissedDosage, setEffectsOfMissedDosage] = useState("");
    const [EffectsOfOverdose, setEffectsOfOverdose] = useState("");
    const [ExpertAdvice, setExpertAdvice] = useState("");
    const [HowToUse, setHowToUse] = useState("");
    const [FAQs, setFAQs] = useState("");
    const [Category, setCategory] = useState("");
    const [CategoryList, setCategorylist] = useState([]);
    const [Tags, setTags] = useState("");
    const [TagsList, setTagsList] = useState([]);
    const [SubCategory, setSubCategory] = useState("");
    const [SubCategoryList, setSubCategoryList] = useState([]);
    const [formStatus, setFormStatus] = useState("");
    const [fileOne, setFileOne] = useState("");
    const handleSubmit = (e) => {
        e.preventDefault();

        var data = {
            productId: ProductId,
            productName: ProductName,
            mrp: MRP,
            discount: Discount,
            sellingPrice: SellingPrice,
            deliveryTime: DeliveryTime,
            packingType: PackingType,
            packaging: Packaging,
            compositions: Compositions,
            usage: Usage,
            nameOfManufacturer: NameOfManufacturer,
            drugSchedule: DrugSchedule,
            aboutSalt: AboutSalt,
            mechanismOfAction: MechanismOfAction,
            pharmacokinetics: Pharmacokinetics,
            durationOfAction: DurationOfAction,
            onsetOfActions: OnsetOfActions,
            halfLife: HalfLife,
            sideEffects: SideEffects,
            contraIndications: ContraIndications,
            precautionsPregnancyRelatedInformation: PrecautionsPregnancyRelatedInformation,
            productAndAlcoholInteraction: ProductAndAlcoholInteraction,
            oldAgeRelatedInformation: OldAgeRelatedInformation,
            breastfeedingRelatedInformation: BreastfeedingRelatedInformation,
            childrenRelatedInformation: ChildrenRelatedInformation,
            indications: Indications,
            interactions: Interactions,
            typicalDosage: TypicalDosage,
            storageRequirements: StorageRequirements,
            effectsOfMissedDosage: EffectsOfMissedDosage,
            expertAdvice: ExpertAdvice,
            effectsOfOverdose: EffectsOfOverdose,
            howToUse: HowToUse,
            fAQs: FAQs,
            category: Category,
            tags: Tags,
            subCategory: SubCategory



        }
        console.log(data)
        const config = {
            headers: { Authorization: `Bearer ${iDtoken}` }
        };

        
        
        axios
            .post(api.baseUrl+"api/create-medicine-dashboard", data,config)
            .then((response) => {

                console.log(response)

                if (
                    response.data.status === true
                ) {
                    setFormStatus(true)

                } else {
                    setFormStatus(false)

                }

                setShowMedicineAddModal(true);



            })
            .catch((error) => console.log(error));

    }

    const changeCategory = (e) => {

       
        setCategory(e.target.value);
       
        const config = {
            headers: { Authorization: `Bearer ${iDtoken}` }
        };

        
        axios
        .get(`${api.baseUrl}api/subCategory-by-categoryId/${e.target.value}`,config)
        .then((response) => {

            setSubCategoryList(response.data.result);
            console.log(response.data.result + " subcategorlist")
        })
        console.log(" hitted api")
    }

    const changeTags = (e) => {
        setTags(e.target.value);
    }

    const changeSubCategory = (e) => {
        console.log(e.target.value)
        setSubCategory(e.target.value);




    }

    const changeCategorylist = (e) => {


        setCategorylist(e.target.value);
    }

    const changeSubCategorylist = (e) => {

        setSubCategoryList(e.target.value);
    }

    const changeTagslist = (e) => {
        setTagsList(e.target.value);
    }



    useEffect(() => {

        const config = {
            headers: { Authorization: `Bearer ${iDtoken}` }
        };
      
        axios
            .get(api.baseUrl+"api/get-all-Category",config)
            .then((response) => {

                setCategorylist(response.data.result);
                console.log(response + " category")
            })

            
    }, []);

   

    useEffect(() => {
        const config = {
            headers: { Authorization: `Bearer ${iDtoken}` }
        };

       
        axios
            .get(api.baseUrl+"api/get-all-tags-dashboard",config)
            .then((response) => {

                setTagsList(response.data.result);
                console.log(response + " tags")
            })

    }, []);

    const handleChange = (e) => {
        if (e.target.name === "productId") {
            console.log(e.target.value)
            setProductId(e.target.value)
        }

        else if (e.target.name === "productName") {
            console.log(e.target.value)
            setProductName(e.target.value)
        }
        else if (e.target.name === "mrp") {
            setMRP(e.target.value)
        }
        else if (e.target.name === "discount") {
            setDiscount(e.target.value)
        }
        else if (e.target.name === "sellingPrice") {
            setSellingPrice(e.target.value)
        }
        else if (e.target.name === "deliveryTime") {
            setDeliveryTime(e.target.value)
        }
        else if (e.target.name === "packingType") {
            setPackingType(e.target.value)
        }
        else if (e.target.name === "packaging") {
            setPackaging(e.target.value)
        }
        else if (e.target.name === "compositions") {
            setCompositions(e.target.value)
        }
        else if (e.target.name === "usage") {
            setUsage(e.target.value)
        }
        else if (e.target.name === "durationOfAction") {
            setDurationOfAction(e.target.value)
        }
        else if (e.target.name === "nameOfManufacturer") {
            setNameOfManufacturer(e.target.value)
        }
        else if (e.target.name === "drugSchedule") {
            setDrugSchedule(e.target.value)
        }
        else if (e.target.name === "aboutSalt") {
            setAboutSalt(e.target.value)
        }
        else if (e.target.name === "mechanismOfAction") {
            setMechanismOfAction(e.target.value)
        }
        else if (e.target.name === "pharmacokinetics") {
            setPharmacokinetics(e.target.value)
        }
        else if (e.target.name === "onsetOfActions") {
            setOnsetOfActions(e.target.value)
        }
        else if (e.target.name === "halfLife") {
            setHalfLife(e.target.value)
        }
        else if (e.target.name === "sideEffects") {
            setSideEffects(e.target.value)
        }
        else if (e.target.name === "contraIndications") {
            setContraIndications(e.target.value)
        }
        else if (e.target.name === "precautionsPregnancyRelatedInformation") {
            setPrecautionsPregnancyRelatedInformation(e.target.value)
        }
        else if (e.target.name === "productAndAlcoholInteraction") {
            setProductAndAlcoholInteraction(e.target.value)
        }
        else if (e.target.name === "oldAgeRelatedInformation") {
            setOldAgeRelatedInformation(e.target.value)
        }
        else if (e.target.name === "breastfeedingRelatedInformation") {
            setBreastfeedingRelatedInformation(e.target.value)
        }
        else if (e.target.name === "childrenRelatedInformation") {
            setChildrenRelatedInformation(e.target.value)
        }
        else if (e.target.name === "indications") {
            setIndications(e.target.value)
        }
        else if (e.target.name === "interactions") {
            setInteractions(e.target.value)
        }
        else if (e.target.name === "typicalDosage") {
            setTypicalDosage(e.target.value)
        }
        else if (e.target.name === "storageRequirements") {
            setStorageRequirements(e.target.value)
        }
        else if (e.target.name === "effectsOfMissedDosage") {
            setEffectsOfMissedDosage(e.target.value)
        }
        else if (e.target.name === "effectsOfOverdose") {
            setEffectsOfOverdose(e.target.value)
        }
        else if (e.target.name === "expertAdvice") {
            setExpertAdvice(e.target.value)
        }
        else if (e.target.name === "howToUse") {
            setHowToUse(e.target.value)
        }
        else if (e.target.name === "fAQs") {
            setFAQs(e.target.value)
        }



    }


    // const handleSubmit = () => {
    //     console.log("fsdhfh")
    // }






    return (
        <>
            <div class="breadcome-area">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="breadcome-list">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div class="breadcomb-wp">
                                            <div class="breadcomb-icon">
                                                <i class="icon nalika-home"></i>
                                            </div>
                                            <div class="breadcomb-ctn">
                                                <h2>Medicine Add</h2>
                                                <p>Welcome to Glizmed <span class="bread-ntd">Admin Panel</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div class="breadcomb-report">
                                            <button data-toggle="tooltip" data-placement="left" title="Download Report" class="btn"><i class="icon nalika-download"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-fluid mb-5">
                    <form onSubmit={handleSubmit} className="form-bg p-2 py-4">
                        <div className="MedicineMain col-lg-12">
                            <div className="row">

                                <div className="col-lg-4">
                                    <label className="LabelName">Product Id</label>
                                    <input type="text" name="productId" placeholder="ProductId" defaultValue={ProductId} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">Product Name</label>
                                    <input type="text" name="productName" placeholder="ProductName" defaultValue={ProductName} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">MRP</label>
                                    <input type="number" name="mrp" placeholder="MRP" defaultValue={MRP} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">Discount</label>
                                    <input type="number" name="discount" placeholder="Discount" defaultValue={Discount} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">Sellling Price</label>
                                    <input type="text" name="sellingPrice" placeholder="SellingPrice" defaultValue={SellingPrice} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">Delivery Time</label>
                                    <input type="text" name="deliveryTime" placeholder="DeliveryTime" defaultValue={DeliveryTime} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">Packing Type</label>
                                    <input type="text" name="packingType" placeholder="Packing Type" defaultValue={PackingType} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">Packaging</label>
                                    <input type="text" name="packaging" placeholder="Packaging" defaultValue={Packaging} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">Compositions</label>
                                    <input type="text" name="compositions" placeholder="compositions" defaultValue={Compositions} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">Usage</label>
                                    <input type="text" name="usage" placeholder="Usage" defaultValue={Usage} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">Name Of Manufacturer</label>
                                    <input type="text" name="nameOfManufacturer" placeholder="NameOfManufacturer" defaultValue={NameOfManufacturer} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">Drug Schedule</label>
                                    <input type="text" name="drugSchedule" placeholder="DrugSchedule" defaultValue={DrugSchedule} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">About Salt</label>
                                    <input type="text" name="aboutSalt" placeholder="AboutSalt" defaultValue={AboutSalt} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName"> Mechanism Of Action</label>
                                    <input type="text" name="mechanismOfAction" placeholder="MechanismOfAction" defaultValue={MechanismOfAction} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">Pharmacokineticst</label>
                                    <input type="text" name="pharmacokinetics" placeholder="Pharmacokinetics" defaultValue={Pharmacokinetics} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">On set Of Action</label>
                                    <input type="text" name="onsetOfActions" placeholder="OnsetOfActions" defaultValue={OnsetOfActions} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName"> Duration Of Action</label>
                                    <input type="text" name="durationOfAction" placeholder="DurationOfAction" defaultValue={DurationOfAction} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">Half Life</label>
                                    <input type="text" name="halfLife" placeholder="HalfLife" defaultValue={HalfLife} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">Side Effects</label>
                                    <input type="text" name="sideEffects" placeholder="SideEffects" defaultValue={SideEffects} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">Contra Indications</label>
                                    <input type="text" name="contraIndications" placeholder="ContraIndications" defaultValue={ContraIndications} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">Precautions Pregnancy Related Information</label>
                                    <input type="text" name="precautionsPregnancyRelatedInformation" placeholder="PrecautionsPregnancyRelatedInformation" defaultValue={PrecautionsPregnancyRelatedInformation} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">Product And Alcohol Interaction</label>
                                    <input type="text" name="productAndAlcoholInteraction" placeholder="ProductAndAlcoholInteraction" defaultValue={ProductAndAlcoholInteraction} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">Old Age Related Information</label>
                                    <input type="text" name="oldAgeRelatedInformation" placeholder="PrecautionsPregnancyRelatedInformation" defaultValue={OldAgeRelatedInformation} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">Breast feeding Related Information</label>
                                    <input type="text" name="breastfeedingRelatedInformation" placeholder="BreastfeedingRelatedInformation" defaultValue={BreastfeedingRelatedInformation} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">Children Related Information</label>
                                    <input type="text" name="childrenRelatedInformation" placeholder="ChildrenRelatedInformation" defaultValue={ChildrenRelatedInformation} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">Indications</label>
                                    <input type="text" name="indications" placeholder="Indications" defaultValue={Indications} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName"> Interactions</label>
                                    <input type="text" name="interactions" placeholder="Interactions" defaultValue={Interactions} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">Typical Dosage</label>
                                    <input type="text" name="typicalDosage" placeholder="Typical Dosage" defaultValue={TypicalDosage} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">Storage Requirements</label>
                                    <input type="text" name="storageRequirements" placeholder="StorageRequirements" defaultValue={StorageRequirements} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">Effects Of Missed Dosage</label>
                                    <input type="text" name="effectsOfMissedDosage" placeholder="EffectsOfMissedDosage" defaultValue={EffectsOfMissedDosage} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">Effects Of Overdose</label>
                                    <input type="text" name="effectsOfOverdose" placeholder="EffectsOfOverdose" defaultValue={EffectsOfOverdose} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">Expert Advice</label>
                                    <input type="text" name="expertAdvice" placeholder="ExpertAdvice" defaultValue={ExpertAdvice} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">How To Use</label>
                                    <input type="text" name="howToUse" placeholder="HowToUse" defaultValue={HowToUse} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                    <label className="LabelName">FAQs</label>
                                    <input type="text" name="fAQs" placeholder="FAQs" defaultValue={FAQs} onChange={handleChange} className="medicineAddFormIpTag" />
                                </div>
                                <div className="col-lg-4">
                                <label className="LabelName">Choose Category</label>
                                    <select
                                        className="form-control"
                                        placeholder="categorylist"

                                        onChange={(e) => {
                                            changeCategory(e);
                                        }}
                                    // value={Categorylist}
                                    >
                                        <option key={"chooseCategory"}>{"--Choose Category--"}</option>

                                        {CategoryList.map((value, i) => {
                                            return (
                                                <option key={i} value={value._id} >
                                                    {value.categoryName}

                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                {SubCategoryList.length>0 ?  
                                <div className="col-lg-4">
                                    <label className="LabelName">Choose Sub Category</label>
                                    <select
                                        className="form-control"
                                        placeholder="State"
                                        onChange={(e) => {
                                            changeSubCategory(e);
                                        }}
                                        value={SubCategory}
                                    >
                                        <option key={"chooseSubCategory"}>{"--Choose SubCategory--"}</option>
                                        {SubCategoryList.map((value, i) => {
                                            return (
                                                <option key={i} value={value._id} >
                                                    {value.subCategoryName}

                                                </option>
                                            );
                                        })}
                                    </select>
                            </div>: <></>}
                            
                            </div>
                            
                            <div className="row">
                                <div className="col-lg-4">
                                    <label className="LabelName">Choose Tags</label>
                                    <select
                                        className="form-control"
                                        placeholder="State"
                                        onChange={(e) => {
                                            changeTags(e);
                                        }}
                                        value={Tags}
                                    >
                                        <option key={"chooseTags"}>{"--Choose Tags--"}</option>

                                        {TagsList.map((value, i) => {
                                            return (
                                                <option key={i} value={value._id} >
                                                    {value.tagsName}

                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>

                                <div className="col-lg-4">
                                    <label className="LabelName">Choose Images</label>
                                    <label className="uploadfile"> Upload File
                                            <label htmlFor="file1" className="uploadnow" > Choose image <i class="fa-solid fa-arrow-right"></i>
                                                <input
                                                    style={{ display: "none", visibility: "none" }}
                                                    type="file"
                                                    id="file1"
                                                    name="fileOne"
                                                    multiple
                                                    onChange={(e) => {
                                                        setFileOne(e.target.files);
                                                  
                                                    }}
                                                />
                                            </label>
                                        </label>
                                </div>
                            </div>


                            <input type="submit" name="" value="Submit" className="mt-3" />
                        </div>


                    </form>
                </div>

            </div>


            <FormModal show={showMedicineAddModal}
                setShow={setShowMedicineAddModal}
                formStatus={formStatus}
                navigate={navigate}
                path="medicineAdd" />

        </>

    )
}
export default MedicineAdd;