
import React,{useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import {useAuthDataContext} from "./AuthDataProvider";
import axios from "axios";
import ReactPaginate from "react-paginate";

import { api } from "../Utils/ApiUrl";
       

function ApprovedPrescription(){
    let navigate = useNavigate();
   const [orderList, setOrderList] = useState([]);

const { iDtoken }= useAuthDataContext();

    const [pageCount, setpageCount] = useState(0);
   
    //the _id of order the user tapped on
    const [orderId, setOrderId]= useState("");

 


    useEffect(() => {

        var data= {
             status:"Approved",
            
         }
         const config = {
            headers: { Authorization: `Bearer ${iDtoken}` }
        };

       
         axios
             .post(api.baseUrl+"api/get-all-prescription-orders",data,config)
             .then((response) => {
                 
                 setOrderList(response.data.result);
                 
                 
             })
 
        
       }, []);




    return(
        <>
        <div class="breadcome-area">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="breadcome-list">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div class="breadcomb-wp">
                                        <div class="breadcomb-icon">
                                            <i class="icon nalika-home"></i>
                                        </div>
                                        <div class="breadcomb-ctn">
                                            <h2>Order List</h2>
                                            <p>Welcome to Glizmed <span class="bread-ntd">Admin Panel</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div class="breadcomb-report">
                                        <button data-toggle="tooltip" data-placement="left" title="Download Report" class="btn"><i class="icon nalika-download"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid">
                <div className="maindiv1">
                    <div class="product-status-wrap">
                        <h4>Order Table</h4>
                        <table >
                            <tr >
                                <th>Date</th>
                                <th>Time</th>
                                <th >phoneNumber</th>
                                <th >Status</th>
                                
                            </tr>
                            {orderList.map(item => (
                                <tr>
                                <td>{item.date}/{item.month}/{item.year}</td>
                                <td>{item.time}</td>
                                <td>{item.phoneNumber}</td>
                                <td>{item.status}</td>
                                
                                </tr>
        
                            ))}
        
                        </table>
                        
                        {/* <ReactPaginate className="pagination"
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-item"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-item"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-item"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-item"}
                            activeClassName={"activepagination"}
                        />  */}
                        
                    </div>
                </div>

            </div>

        </div>
                 

                   </>
                
     )

}
export default ApprovedPrescription;