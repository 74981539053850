import "../App.css";

import React from "react";
import { useState, useEffect } from "react";
import { useAuthDataContext } from "./AuthDataProvider"
import axios from "axios";

import { api } from "../Utils/ApiUrl";

function UpdateSubCategory(props) {

    const { updateSubCategoryId } = useAuthDataContext();
    const [updateSubCatName, setUpdateSubCatName] = useState("");
    const [UpdateSubCategoryList, setUpdateSubCategoryList] = useState([]);

    const [existingImagesArr, setExistingImagesArr] = useState([]);
    const [fileOne, setFileOne] = useState("");


    useEffect(() => {
        var data = {
            id: updateSubCategoryId
        }
        axios
            .post(api.baseUrl+"api/get-one-Subcategory-dashboard", data)
            .then((response) => {

                console.log(response.data.result + " response.data.result");
                setUpdateSubCategoryList(response.data.result);
                setUpdateSubCatName(response.data.result[0].subCategoryName);
                setExistingImagesArr(response.data.result[0].imagesArr);
            })
            .catch((error) => console.log(error));

    }, []);



    const onImageChange = (e) => {
        // const [file] = e.target.files;

        setFileOne(e.target.files);
    }

    const handleUpdate = () => {

        console.log(updateSubCatName + " updateSubCatName")
        const data = new FormData();
        data.append("subCategoryName", updateSubCatName);
        data.append("id", updateSubCategoryId);
        data.append("existingImagesArr", JSON.stringify(existingImagesArr));

        for (let i = 0; i < fileOne.length; i++) {
            data.append("fileOne", fileOne[i]);
        }

        axios
            .post(api.baseUrl+"api/update-SubCategory-by-id", data)
            .then((response) => {
                console.log(response.status);

            })
            .catch((error) => console.log(error));

    }

    const removeImageFromExistingArr = (index) => {
        const list = [...existingImagesArr];
        list.splice(index, 1);
        setExistingImagesArr(list);
    }

    return (
        <div className="breadcome-area">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="breadcome-list">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="breadcomb-wp">
                                        <div className="breadcomb-icon">
                                            <i className="icon nalika-home"></i>
                                        </div>
                                        <div className="breadcomb-ctn">
                                            <h2>Update SubCategory</h2>
                                            <p>Welcome to Glizmed <span className="bread-ntd">Admin Panel</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="breadcomb-report">
                                        <button data-toggle="tooltip" data-placement="left" title="Download Report" className="btn"><i className="icon nalika-download"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="col-lg-3">
                <label className="LabelName">Update SubCategory</label>
                <input type="text" className="SubcategoryName" placeholder="Enter update Subcategory" defaultValue={updateSubCatName} onChange={(e) => setUpdateSubCatName(e.target.value)} />

            </div>
            <div className="col-lg-9">   {existingImagesArr.length > 0 ? existingImagesArr.map((x, i) => (<>
                <img src={api.baseUrl+`${x}`} /> <button onClick={() => { removeImageFromExistingArr(i) }} > Remove</button>
            </>)) : <></>}  </div>

            <div className="col-lg-12">
                <div>
                    <h5> Upload Images for this category</h5>

                    <br />
                    <input type="file" onChange={onImageChange} />
                </div>
            </div>

            <button onClick={() => handleUpdate()} >Update</button>
        </div>

    )
        ;
}

export default UpdateSubCategory;
