import "../App.css";

import React from "react";
import { useState, useEffect } from "react";
import { useAuthDataContext } from "./AuthDataProvider"
import axios from "axios";

import { api } from "../Utils/ApiUrl";

function UpdateMedicine(props) {

    const { updateMedicineId } = useAuthDataContext();

    const [UpdateMedicineList, setUpdateMedicineList] = useState([]);




    const [ProductId, setProductId] = useState("");
    const [ProductName, setProductName] = useState("");
    const [MRP, setMRP] = useState("");
    const [Discount, setDiscount] = useState("");
    const [SellingPrice, setSellingPrice] = useState("");
    const [DeliveryTime, setDeliveryTime] = useState("");
    const [PackingType, setPackingType] = useState("");
    const [Packaging, setPackaging] = useState("");
    const [Compositions, setCompositions] = useState("");
    const [Usage, setUsage] = useState("");
    const [NameOfManufacturer, setNameOfManufacturer] = useState("");
    const [DrugSchedule, setDrugSchedule] = useState("");
    const [AboutSalt, setAboutSalt] = useState("");
    const [MechanismOfAction, setMechanismOfAction] = useState("");
    const [Pharmacokinetics, setPharmacokinetics] = useState("");
    const [DurationOfAction, setDurationOfAction] = useState("");
    const [OnsetOfActions, setOnsetOfActions] = useState("");
    const [HalfLife, setHalfLife] = useState("");
    const [SideEffects, setSideEffects] = useState("");
    const [ContraIndications, setContraIndications] = useState("");
    const [PrecautionsPregnancyRelatedInformation, setPrecautionsPregnancyRelatedInformation] = useState("");
    const [ProductAndAlcoholInteraction, setProductAndAlcoholInteraction] = useState("");
    const [OldAgeRelatedInformation, setOldAgeRelatedInformation] = useState("");
    const [BreastfeedingRelatedInformation, setBreastfeedingRelatedInformation] = useState("");
    const [ChildrenRelatedInformation, setChildrenRelatedInformation] = useState("");
    const [Indications, setIndications] = useState("");
    const [Interactions, setInteractions] = useState("");
    const [TypicalDosage, setTypicalDosage] = useState("");
    const [StorageRequirements, setStorageRequirements] = useState("");
    const [EffectsOfMissedDosage, setEffectsOfMissedDosage] = useState("");
    const [EffectsOfOverdose, setEffectsOfOverdose] = useState("");
    const [ExpertAdvice, setExpertAdvice] = useState("");
    const [HowToUse, setHowToUse] = useState("");
    const [FAQs, setFAQs] = useState("");
    const [Category, setCategory] = useState("");
    const [CategoryList, setCategorylist] = useState([]);
    const [Tags, setTags] = useState("");
    const [TagsList, setTagsList] = useState([]);
    const [SubCategory, setSubCategory] = useState("");
    const [SubCategoryList, setSubCategoryList] = useState([]);
    const [formStatus, setFormStatus] = useState("");
    const [fileOne, setFileOne] = useState("");



    const [existingImagesArr, setExistingImagesArr] = useState([]);



    useEffect(() => {
        var data = {
            id: updateMedicineId
        }
        axios
            .post(api.baseUrl+"api/get-one-medicine-dashboard", data)
            .then((response) => {

                console.log(response.data.result[0].ProductName + " response.data.result");

                setUpdateMedicineList(response.data.result);
                setProductId(response.data.result[0].ProductID);
                setProductName(response.data.result[0].ProductName)
                setMRP(response.data.result[0].MRP)
                setDiscount(response.data.result[0].Discount)
                setSellingPrice(response.data.result[0].SellingPrice)
                setDeliveryTime(response.data.result[0].DeliveryTime)
                setPackingType(response.data.result[0].PackingType)
                setPackaging(response.data.result[0].Packaging)
                setCompositions(response.data.result[0].Compositions)
                setUsage(response.data.result[0].Usage)
                setNameOfManufacturer(response.data.result[0].NameOfManufacturer)
                setDrugSchedule(response.data.result[0].DrugSchedule)
                setAboutSalt(response.data.result[0].AboutSalt)
                setMechanismOfAction(response.data.result[0].MechanismOfAction)
                setPharmacokinetics(response.data.result[0].Pharmacokinetics)
                setDurationOfAction(response.data.result[0].DurationOfAction)
                setOnsetOfActions(response.data.result[0].OnsetOfActions)
                setHalfLife(response.data.result[0].HalfLife)
                setSideEffects(response.data.result[0].SideEffects)
                setContraIndications(response.data.result[0].ContraIndications)
                setPrecautionsPregnancyRelatedInformation(response.data.result[0].PrecautionsPregnancyRelatedInformation)
                setProductAndAlcoholInteraction(response.data.result[0].ProductAndAlcoholInteraction)
                setOldAgeRelatedInformation(response.data.result[0].OldAgeRelatedInformation)
                setBreastfeedingRelatedInformation(response.data.result[0].BreastfeedingRelatedInformation)
                setChildrenRelatedInformation(response.data.result[0].ChildrenRelatedInformation)
                setIndications(response.data.result[0].Indications)
                setInteractions(response.data.result[0].Interactions)
                setTypicalDosage(response.data.result[0].TypicalDosage)
                setStorageRequirements(response.data.result[0].StorageRequirements)
                setEffectsOfMissedDosage(response.data.result[0].EffectsOfMissedDosage)
                setEffectsOfOverdose(response.data.result[0].EffectsOfOverdose)
                setExpertAdvice(response.data.result[0].ExpertAdvice)
                setHowToUse(response.data.result[0].HowToUse)
                setFAQs(response.data.result[0].FAQs)
                setCategory(response.data.result[0].Category)
                setCategorylist(response.data.result[0].CategoryList)
                setTags(response.data.result[0].Tags)
                setTagsList(response.data.result[0].TagsList)
                setSubCategory(response.data.result[0].SubCategory)
                setSubCategoryList(response.data.result[0].SubCategoryList)

                setExistingImagesArr(response.data.result[0].imagesArr);

                console.log(response.data.result[0].imagesArr)
            })
            .catch((error) => console.log(error));

    }, []);



    const onImageChange = (e) => {
        // const [file] = e.target.files;

        setFileOne(e.target.files);
    }

    const handleUpdate = () => {

        // console.log(updateCatName + " updateCatName")
        const data = new FormData();
        data.append("productId", ProductId);
        data.append("id", updateMedicineId);
        data.append("existingImagesArr", JSON.stringify(existingImagesArr));

        for (let i = 0; i < fileOne.length; i++) {
            data.append("fileOne", fileOne[i]);
        }

        axios
            .post(api.baseUrl+"api/update-medicine-by-id", data)
            .then((response) => {
                console.log(response.status);

            })
            .catch((error) => console.log(error));

    }
   
    


    const handleChange = (e) => {
        if (e.target.name === "productId") {
            console.log(e.target.value)
            setProductId(e.target.value)
        }

        else if (e.target.name === "productName") {
            console.log(e.target.value)
            setProductName(e.target.value)
        }
        else if (e.target.name === "mrp") {
            setMRP(e.target.value)
        }
        else if (e.target.name === "discount") {
            setDiscount(e.target.value)
        }
        else if (e.target.name === "sellingPrice") {
            setSellingPrice(e.target.value)
        }
        else if (e.target.name === "deliveryTime") {
            setDeliveryTime(e.target.value)
        }
        else if (e.target.name === "packingType") {
            setPackingType(e.target.value)
        }
        else if (e.target.name === "packaging") {
            setPackaging(e.target.value)
        }
        else if (e.target.name === "compositions") {
            setCompositions(e.target.value)
        }
        else if (e.target.name === "usage") {
            setUsage(e.target.value)
        }
        else if (e.target.name === "durationOfAction") {
            setDurationOfAction(e.target.value)
        }
        else if (e.target.name === "nameOfManufacturer") {
            setNameOfManufacturer(e.target.value)
        }
        else if (e.target.name === "drugSchedule") {
            setDrugSchedule(e.target.value)
        }
        else if (e.target.name === "aboutSalt") {
            setAboutSalt(e.target.value)
        }
        else if (e.target.name === "mechanismOfAction") {
            setMechanismOfAction(e.target.value)
        }
        else if (e.target.name === "pharmacokinetics") {
            setPharmacokinetics(e.target.value)
        }
        else if (e.target.name === "onsetOfActions") {
            setOnsetOfActions(e.target.value)
        }
        else if (e.target.name === "halfLife") {
            setHalfLife(e.target.value)
        }
        else if (e.target.name === "sideEffects") {
            setSideEffects(e.target.value)
        }
        else if (e.target.name === "contraIndications") {
            setContraIndications(e.target.value)
        }
        else if (e.target.name === "precautionsPregnancyRelatedInformation") {
            setPrecautionsPregnancyRelatedInformation(e.target.value)
        }
        else if (e.target.name === "productAndAlcoholInteraction") {
            setProductAndAlcoholInteraction(e.target.value)
        }
        else if (e.target.name === "oldAgeRelatedInformation") {
            setOldAgeRelatedInformation(e.target.value)
        }
        else if (e.target.name === "breastfeedingRelatedInformation") {
            setBreastfeedingRelatedInformation(e.target.value)
        }
        else if (e.target.name === "childrenRelatedInformation") {
            setChildrenRelatedInformation(e.target.value)
        }
        else if (e.target.name === "indications") {
            setIndications(e.target.value)
        }
        else if (e.target.name === "interactions") {
            setInteractions(e.target.value)
        }
        else if (e.target.name === "typicalDosage") {
            setTypicalDosage(e.target.value)
        }
        else if (e.target.name === "storageRequirements") {
            setStorageRequirements(e.target.value)
        }
        else if (e.target.name === "effectsOfMissedDosage") {
            setEffectsOfMissedDosage(e.target.value)
        }
        else if (e.target.name === "effectsOfOverdose") {
            setEffectsOfOverdose(e.target.value)
        }
        else if (e.target.name === "expertAdvice") {
            setExpertAdvice(e.target.value)
        }
        else if (e.target.name === "howToUse") {
            setHowToUse(e.target.value)
        }
        else if (e.target.name === "fAQs") {
            setFAQs(e.target.value)
        }



    }

    const removeImageFromExistingArr = (index) => {
        const list = [...existingImagesArr];
        list.splice(index, 1);
        setExistingImagesArr(list);
    }
   

    return (
        <div className="breadcome-area">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="breadcome-list">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="breadcomb-wp">
                                        <div className="breadcomb-icon">
                                            <i className="icon nalika-home"></i>
                                        </div>
                                        <div className="breadcomb-ctn">
                                            <h2>Update Medicine</h2>
                                            <p>Welcome to Glizmed <span className="bread-ntd">Admin Panel</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="breadcomb-report">
                                        <button data-toggle="tooltip" data-placement="left" title="Download Report" className="btn"><i className="icon nalika-download"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* <div className="col-lg-3">
                <label className="LabelName">Update Category</label>
                <input type="text" className="categoryName" placeholder="Enter update category" defaultValue={updateCatName} onChange={(e) => setUpdateCatName(e.target.value)} />

            </div> */}


            <div className="MedicineMain col-lg-12">
                <div className="row">
                    {console.log(ProductId + " " + ProductName)}
                    <div className="col-lg-4">
                        <label className="LabelName">Product Id</label>
                        <input type="text" name="productId" placeholder="ProductId" defaultValue={ProductId} onChange={(e) => setProductId(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">Product Name</label>
                        <input type="text" name="productName" placeholder="ProductName" defaultValue={ProductName} onChange={(e) => setProductName(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">MRP</label>
                        <input type="number" name="mrp" placeholder="MRP" defaultValue={MRP} onChange={(e) => setMRP(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">Discount</label>
                        <input type="number" name="discount" placeholder="Discount" defaultValue={Discount} onChange={(e) => setDiscount(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">Sellling Price</label>
                        <input type="text" name="sellingPrice" placeholder="SellingPrice" defaultValue={SellingPrice} onChange={(e) => setSellingPrice(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">Delivery Time</label>
                        <input type="text" name="deliveryTime" placeholder="DeliveryTime" defaultValue={DeliveryTime} onChange={(e) => setDeliveryTime(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">Packing Type</label>
                        <input type="text" name="packingType" placeholder="Packing Type" defaultValue={PackingType} onChange={(e) => setPackingType(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">Packaging</label>
                        <input type="text" name="packaging" placeholder="Packaging" defaultValue={Packaging} onChange={(e) => setPackaging(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">Compositions</label>
                        <input type="text" name="compositions" placeholder="compositions" defaultValue={Compositions} onChange={(e) => setCompositions(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">Usage</label>
                        <input type="text" name="usage" placeholder="Usage" defaultValue={Usage} onChange={(e) => setUsage(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">Name Of Manufacturer</label>
                        <input type="text" name="nameOfManufacturer" placeholder="NameOfManufacturer" defaultValue={NameOfManufacturer} onChange={(e) => setNameOfManufacturer(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">Drug Schedule</label>
                        <input type="text" name="drugSchedule" placeholder="DrugSchedule" defaultValue={DrugSchedule} onChange={(e) => setDrugSchedule(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">About Salt</label>
                        <input type="text" name="aboutSalt" placeholder="AboutSalt" defaultValue={AboutSalt} onChange={(e) => setAboutSalt(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName"> Mechanism Of Action</label>
                        <input type="text" name="mechanismOfAction" placeholder="MechanismOfAction" defaultValue={MechanismOfAction} onChange={(e) => setMechanismOfAction(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">Pharmacokineticst</label>
                        <input type="text" name="pharmacokinetics" placeholder="Pharmacokinetics" defaultValue={Pharmacokinetics} onChange={(e) => setPharmacokinetics(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">On set Of Action</label>
                        <input type="text" name="onsetOfActions" placeholder="OnsetOfActions" defaultValue={OnsetOfActions} onChange={(e) => setOnsetOfActions(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName"> Duration Of Action</label>
                        <input type="text" name="durationOfAction" placeholder="DurationOfAction" defaultValue={DurationOfAction} onChange={(e) => setDurationOfAction(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">Half Life</label>
                        <input type="text" name="halfLife" placeholder="HalfLife" defaultValue={HalfLife} onChange={(e) => setHalfLife(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">Side Effects</label>
                        <input type="text" name="sideEffects" placeholder="SideEffects" defaultValue={SideEffects} onChange={(e) => setSideEffects(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">Contra Indications</label>
                        <input type="text" name="contraIndications" placeholder="ContraIndications" defaultValue={ContraIndications} onChange={(e) => setContraIndications(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">Precautions Pregnancy Related Information</label>
                        <input type="text" name="precautionsPregnancyRelatedInformation" placeholder="PrecautionsPregnancyRelatedInformation" defaultValue={PrecautionsPregnancyRelatedInformation} onChange={(e) => setPrecautionsPregnancyRelatedInformation(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">Product And Alcohol Interaction</label>
                        <input type="text" name="productAndAlcoholInteraction" placeholder="ProductAndAlcoholInteraction" defaultValue={ProductAndAlcoholInteraction} onChange={(e) => setProductAndAlcoholInteraction(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">Old Age Related Information</label>
                        <input type="text" name="oldAgeRelatedInformation" placeholder="PrecautionsPregnancyRelatedInformation" defaultValue={OldAgeRelatedInformation} onChange={(e) => setOldAgeRelatedInformation(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">Breast feeding Related Information</label>
                        <input type="text" name="breastfeedingRelatedInformation" placeholder="BreastfeedingRelatedInformation" defaultValue={BreastfeedingRelatedInformation} onChange={(e) => setBreastfeedingRelatedInformation(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">Children Related Information</label>
                        <input type="text" name="childrenRelatedInformation" placeholder="ChildrenRelatedInformation" defaultValue={ChildrenRelatedInformation} onChange={(e) => setChildrenRelatedInformation(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">Indications</label>
                        <input type="text" name="indications" placeholder="Indications" defaultValue={Indications} onChange={(e) => setIndications(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName"> Interactions</label>
                        <input type="text" name="interactions" placeholder="Interactions" defaultValue={Interactions} onChange={(e) => setInteractions(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">Typical Dosage</label>
                        <input type="text" name="typicalDosage" placeholder="Typical Dosage" defaultValue={TypicalDosage} onChange={(e) => setTypicalDosage(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">Storage Requirements</label>
                        <input type="text" name="storageRequirements" placeholder="StorageRequirements" defaultValue={StorageRequirements} onChange={(e) => setStorageRequirements(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">Effects Of Missed Dosage</label>
                        <input type="text" name="effectsOfMissedDosage" placeholder="EffectsOfMissedDosage" defaultValue={EffectsOfMissedDosage} onChange={(e) => setEffectsOfMissedDosage(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">Effects Of Overdose</label>
                        <input type="text" name="effectsOfOverdose" placeholder="EffectsOfOverdose" defaultValue={EffectsOfOverdose} onChange={(e) => setEffectsOfOverdose(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">Expert Advice</label>
                        <input type="text" name="expertAdvice" placeholder="ExpertAdvice" defaultValue={ExpertAdvice} onChange={(e) => setExpertAdvice(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">How To Use</label>
                        <input type="text" name="howToUse" placeholder="HowToUse" defaultValue={HowToUse} onChange={(e) => setHowToUse(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">FAQs</label>
                        <input type="text" name="fAQs" placeholder="FAQs" defaultValue={FAQs} onChange={(e) => setFAQs(e.target.value)} className="medicineAddFormIpTag" />
                    </div>
                    <div className="col-lg-4">
                        <label className="LabelName">Choose Category</label>
                        {/* <select
                            className="form-control"
                            placeholder="categorylist"

                            // onChange={(e) => {
                            //     changeCategory(e);
                            // }}
                        // value={Categorylist}
                        onChange={(e) => setCategorylist(e.target.value)} 
                        >
                            <option key={"chooseCategory"}>{"--Choose Category--"}</option>

                            {CategoryList.map((value, i) => {
                                return (
                                    <option key={i} value={value._id} >
                                        {value.categoryName}

                                    </option>
                                );
                            })}
                        </select> */}
                    </div>
                    {/* {SubCategoryList.length > 0 ?
                        <div className="col-lg-4">
                            <label className="LabelName">Choose Sub Category</label>
                            <select
                                className="form-control"
                                placeholder="State"
                                // onChange={(e) => {
                                //     changeSubCategory(e);
                                // }}
                                // value={SubCategory}
                                onChange={(e) => setSubCategory(e.target.value)} 
                            >
                                <option key={"chooseSubCategory"}>{"--Choose SubCategory--"}</option>
                                {SubCategoryList.map((value, i) => {
                                    return (
                                        <option key={i} value={value._id} >
                                            {value.subCategoryName}

                                        </option>
                                    );
                                })}
                            </select>
                        </div> : <></>} */}

                </div>

                <div className="row">
                    <div className="col-lg-4">
                        <label className="LabelName">Choose Tags</label>
                        {/* <select
                            className="form-control"
                            placeholder="State"
                            // onChange={(e) => {
                            //     changeTags(e);
                            // }}
                            // value={Tags}
                            onChange={(e) => setTags(e.target.value)} 
                        >
                            <option key={"chooseTags"}>{"--Choose Tags--"}</option>

                            {TagsList.map((value, i) => {
                                return (
                                    <option key={i} value={value._id} >
                                        {value.tagsName}

                                    </option>
                                );
                            })}
                        </select> */}
                    </div>

                    {/* <div className="col-lg-4">
                        <label className="LabelName">Choose Images</label>
                        <label className="uploadfile"> Upload File
                            <label htmlFor="file1" className="uploadnow" > Choose image <i class="fa-solid fa-arrow-right"></i>
                                <input
                                    style={{ display: "none", visibility: "none" }}
                                    type="file"
                                    id="file1"
                                    name="fileOne"
                                    multiple
                                    onChange={(e) => {
                                        setFileOne(e.target.files);

                                    }}
                                />
                            </label>
                        </label>
                    </div> */}
                </div>


                {/* <input type="submit" name="" value="Submit" className="mt-3" /> */}
            </div>



            
            <div className="col-lg-9">   {existingImagesArr.length > 0 ? existingImagesArr.map((x, i) => (<>
                <img src={api.baseUrl+`${x}`} /> <button onClick={() => { removeImageFromExistingArr(i) }} > Remove</button>
            </>)) : <></>}  </div>

            <div className="col-lg-12">
                <div>
                    <h5> Upload Images for this category</h5>

                    <br />
                    <input type="file" onChange={onImageChange} />
                </div>
            </div>

            <button onClick={() => handleUpdate()} >Update</button>
        </div>

    )
        ;
}

export default UpdateMedicine;
