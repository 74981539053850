import "../App.css";

import React from "react";
import { useState ,useEffect} from "react";

function Test(props) {

    const [name, setName] = useState("");
    useEffect(() => {
  
         
           
       }, []);
  return (
    <div className="test">
    <div className="test2">fhsdjfhsjdhfsjdhf</div>
      </div>
  )
;
}

export default Test;
