import React from "react";
import { useState,useEffect} from "react";
import axios from "axios";
import {useAuthDataContext} from "./AuthDataProvider";
import PresOrderStatusModal from "../Utils/PresOrderStatusModal";
import { useNavigate } from "react-router-dom";
import { api } from "../Utils/ApiUrl";

export default function PrescriptionDetail(props) {
    let navigate = useNavigate();

    const { tappedPrescriptionArr
         ,handleTappedPrescriptionArrRemoveItem ,
         tappedPrescriptionImageArray,iDtoken
         
     }= useAuthDataContext();



     const [searchStr,setSearchStr] = useState();
     const [orderStatus,setOrderStatus] = useState(true);
     const [showOrderStatusModal,setShowOrderStatusModal] = useState(false);

     const [searchResultArr,setSearchResultArr] = useState([]);
     const [digitalPresImg, setDigitalPresImg] = useState("");

     const [medicineArr, setMedicineArr] = useState([]);
     const [fileOne, setFileOne] = useState("");
  

  const handleSearchAndAddChange = (e) => {

    setSearchStr(e.target.value);
    
  };

  
 const onImageChange = (e) => {
      const [file] = e.target.files;
      setDigitalPresImg(URL.createObjectURL(file));
      setFileOne(e.target.files);
    }

   

  const handleApproveClick= () => {

    const data = new FormData();
    
        data.append("phoneNumber", tappedPrescriptionArr[0].phoneNumber);
        data.append("status","Approved");
        data.append("id",tappedPrescriptionArr[0]._id);
        data.append("customerId",tappedPrescriptionArr[0].customerId);
        data.append("tappedPrescriptionImageArray",JSON.stringify(tappedPrescriptionImageArray));
        data.append("medicineArr",JSON.stringify(medicineArr));

        for (let i = 0; i < fileOne.length; i++) {
          data.append("fileOne", fileOne[i]);
        }
    
       

        const config = {
            headers: { Authorization: `Bearer ${iDtoken}` }
        };
     
       

        axios
          .post(api.baseUrl+"api/update-prescription-by-id", data,config)
          .then((response) => {
            setOrderStatus(true);
            setShowOrderStatusModal(true);
          })
          .catch((error) => console.log(error));

  }

    const handleRejectClick= () => {

        const data = new FormData();
    
        data.append("phoneNumber", tappedPrescriptionArr[0].phoneNumber);
        data.append("status","Rejected");
        data.append("id",tappedPrescriptionArr[0]._id);
        data.append("customerId",tappedPrescriptionArr[0].customerId);
        data.append("tappedPrescriptionImageArray",tappedPrescriptionImageArray);
        data.append("medicineArr",medicineArr);


        for (let i = 0; i < fileOne.length; i++) {
          data.append("fileOne", fileOne[i]);
        }
    
        const config = {
            headers: { Authorization: `Bearer ${iDtoken}` }
        };

        
        axios
          .post( api.baseUrl+"api/update-prescription-by-id", data,config)
          .then((response) => {
            setOrderStatus(false);
            setShowOrderStatusModal(true);
          })
          .catch((error) => console.log(error));
            
            
    
  }

//   useEffect(() => {   
//     console
//     setTappedPrescriptionImageArray(tappedPrescriptionArr) 
//    }, []);

  const handleAddMedicineToMedicineArr = (value) => {
    const list = [...medicineArr];

    list.push(value);
   
    setMedicineArr(list);
   
  }

  const handleSearchAndAddClick = ()=> {
    var data = {
        searchString: searchStr,
       
    }
    const config = {
        headers: { Authorization: `Bearer ${iDtoken}` }
    };

  
    axios
        .post(api.baseUrl+"api/search-medicine", data,config)
        .then((response) => {

            
            setSearchResultArr(response.data.result);
        }
        
        
        )
        .catch((error) => console.log(error));
  }

  return (<>
    <div class="breadcome-area" >
    
    <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="breadcome-list">
              <div className="row">
                <div className="col-lg-8">
                    <div className="orderMedicineDetail">
                      <p className="mb-4">Prescription Image : </p> 
                      <div className="row">
                        {tappedPrescriptionImageArray.length > 0 ? tappedPrescriptionImageArray.map((x, i) => (<>
                        <div className="col-6" key={i}> 
                        
                       
                        <img src={api.baseUrl+`${x}`} />
                        
                        <button onClick={()=>{handleTappedPrescriptionArrRemoveItem(i)}}> Remove</button>
                        </div>


                        </>
                        )) : <> Not Image Found </>}

                        <div className="col-lg-12 mt-4">
                            <h5> Upload a digital prescription for this customer</h5>

                            <br />
                            <input type="file" onChange={onImageChange} />
                              <img src={digitalPresImg} alt="" />

                        </div>
                      </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="btnModalParent mb-5">
                        <button className="btn-accept" onClick={()=>{handleApproveClick()}}> Approve</button>
                        <button className="btn-reject" onClick={()=> {handleRejectClick()}}> Reject</button>
                    </div>

                    <div className="addAlternateMedicine"> 
                      <h6> Search and Add alternate medicines</h6>
                      <input type="text" onChange={(e)=>{handleSearchAndAddChange(e)}} />
                      <button onClick={handleSearchAndAddClick}> Search medicine</button> 
                      {searchResultArr.length >0 ? <>{searchResultArr.map((x, i) => (<> <div className="med-listing">
                          Medicine Name:{x.name} <button onClick={()=>{handleAddMedicineToMedicineArr(x)}}> Add</button>
                          </div> </>))}</>:<></>}
                              </div>
                              <div>
                                  {medicineArr.length>0? <> {medicineArr.map((x,i)=>(<div>
                                      Name: {x.name}
                                  </div>))}
                      </>:<></>}
                    </div>
                </div>

              </div>
            </div>

            <div class="container-fluid">
              <div className="row">
                <div className="cust-details">
                  <div className="orderCustomerDetail"> 
                    <h5 className="mb-4"> Customer Details</h5>

                    <div className="row">
                        {/* {tappedCustomerArr.map((x, i) => (<>
                          <div className="col-6" key={i}> 
                          <p>Customer Name: {x.firstName} {x.lastName}</p> 
                          <p>Date of brith: {x.dob}</p>
                          <p>phoneNumber: {x.phoneNumber}</p> 
                          <p>Email : {x.email}</p> 
                          </div>
                          <div className="col-6" key={i}>
                          <p>Address: {x.address}</p> 
                          <p>landmark: {x.landmark}</p> 
                          <p>Pincode: {x.pincode}</p> 
                          <p>State: {x.state}</p> 
                          <p>City: {x.city}</p>
                          </div>
                        </>))} */}
                        <div className="col-6">
                        <p>Customer Name: Yakub shaikh</p> 
                        <p>Date of brith: asdasdf </p> 
                        <p>phoneNumber: 12341234234</p> 
                        <p>Email : asdfasdfasdfasdfasdf</p> 
                        </div>
                        <div className="col-6">
                        <p>Address: asdfasdfasdfasdfasdfasdfasdfasdf</p> 
                        <p>landmark: asdfasdfasdfasdf</p> 
                        <p>Pincode: 1234234</p> 
                        <p>State: asasdfasdfasdfas</p> 
                        <p>City: asdfasdfasdf</p>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      
    </div>
    
    <PresOrderStatusModal show={showOrderStatusModal}
    setShow={setShowOrderStatusModal}
    navigate={navigate}
    orderStatus={orderStatus}
    />

    </>
  );
}