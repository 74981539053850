import React from "react";
import { useState} from "react";
import axios from "axios";
import {useAuthDataContext} from "./AuthDataProvider";

import OrderStatusModal from "../Utils/OrderStatusModal";
import { useNavigate } from "react-router-dom";
import { api } from "../Utils/ApiUrl";
export default function OrderDetail(props) {
    let navigate = useNavigate();
    const { 
       tappedMedicineArr,
        setTappedMedicineArr,
   tappedCustomerArr, 
   handleTappedMedicineArrRemoveItem, tappedOrderId,iDtoken }= useAuthDataContext();


     const [searchStr,setSearchStr] = useState();
     const [orderStatus,setOrderStatus] = useState(true);
     const [showOrderStatusModal,setShowOrderStatusModal] = useState(false);

     const [searchResultArr,setSearchResultArr] = useState([]);



  const handleSearchAndAddChange = (e) => {

    setSearchStr(e.target.value);
    
  };

  const handleApproveClick= () => {

    var data = {
        id: tappedOrderId,
       status:"Approved",
       medicineArr:tappedMedicineArr
    }

    const config = {
        headers: { Authorization: `Bearer ${iDtoken}` }
    };
   
    axios
        .post(api.baseUrl+"api/update-order-by-id", data,config)
        .then((response) => {

            
            setSearchResultArr(response.data.result);
            setOrderStatus(true);
            setShowOrderStatusModal(true);
            
        }
        
        
        )
        .catch((error) => console.log(error));

  }

    const handleRejectClick= () => {

        var data = {
            id: tappedOrderId,
           status:"Rejected",
           medicineArr:tappedMedicineArr
        }
        const config = {
            headers: { Authorization: `Bearer ${iDtoken}` }
        };
    
       
        axios
            .post(api.baseUrl+"api/update-order-by-id", data,config)
            .then((response) => {
    
                
                setSearchResultArr(response.data.result);
                setOrderStatus(false);
                setShowOrderStatusModal(true);
            }
            
            
            )
            .catch((error) => console.log(error));
            
            
    
  }

  const handleAddMedicineToCustomerOrder = (value) => {
    const list = [...tappedMedicineArr];

    list.push(value);
    setTappedMedicineArr(list);
   
  }

  const handleSearchAndAddClick = ()=> {
    var data = {
        searchString: searchStr,
       
    }
    const config = {
        headers: { Authorization: `Bearer ${iDtoken}` }
    };

   

    axios
        .post(api.baseUrl+"api/search-medicine", data,config)
        .then((response) => {

            
            setSearchResultArr(response.data.result);
        }
        
        
        )
        .catch((error) => console.log(error));
  }

  return (<>
    <div class="breadcome-area" >
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="breadcome-list">
              <div className="row">
                <div className="col-lg-8">
                    <div className="orderMedicineDetail">
                      <div className="row">
                        {tappedMedicineArr.map((x, i) => (<>
                          <div className="col-12" key={i}> 
                          <p>Name: {x.name}</p> 
                          <p>Price: {x.price}</p> 
                          <p>Count: {x.count}</p> 
                          <button onClick={()=>{handleTappedMedicineArrRemoveItem(i)}}> Remove</button>
                          </div>


                          </>
                        ))}
                      </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="btnModalParent mb-5">
                        <button className="btn-accept" onClick={()=>{handleApproveClick()}}> Approve</button>
                        <button className="btn-reject" onClick={()=> {handleRejectClick()}}> Reject</button>
                    </div>

                    <div className="addAlternateMedicine"> 
                      <h6> Search and Add alternate medicines</h6>
                      <input type="text" onChange={(e)=>{handleSearchAndAddChange(e)}} />
                      <button onClick={handleSearchAndAddClick}> Search medicine</button> 
                      {searchResultArr.length >0 ? <>{searchResultArr.map((x, i) => (<> <div className="med-listing">
                          Medicine Name:{x.name} <button onClick={()=>{handleAddMedicineToCustomerOrder(x)}}> Add</button>
                          </div> </>))}</>:<></>}
                  </div>
                </div>

              </div>
            </div>

            <div class="container-fluid">
              <div className="row">
                <div className="cust-details">
                  <div className="orderCustomerDetail"> 
                    <h5 className="mb-4"> Customer Details</h5>

                    <div className="row">
                        {tappedCustomerArr.map((x, i) => (<>
                          <div className="col-6" key={i}> 
                          <p>Customer Name: {x.firstName} {x.lastName}</p> 
                          <p>Date of brith: {x.dob}</p>
                          <p>phoneNumber: {x.phoneNumber}</p> 
                          <p>Email : {x.email}</p> 
                          </div>
                          <div className="col-6" key={i}>
                          <p>Address: {x.address}</p> 
                          <p>landmark: {x.landmark}</p> 
                          <p>Pincode: {x.pincode}</p> 
                          <p>State: {x.state}</p> 
                          <p>City: {x.city}</p>
                          </div>
                        </>))}

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    
    <OrderStatusModal show={showOrderStatusModal}
    setShow={setShowOrderStatusModal}
    navigate={navigate}
    orderStatus={orderStatus}
    />
    </>
  );
}