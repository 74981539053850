// import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthDataContext } from "./AuthDataProvider"
import axios from "axios";
import DeleteConfirmationModal from "../Utils/DeleteConfirmationModal";

import { api } from "../Utils/ApiUrl";


function SubCategoryList(){


    const { iDtoken }= useAuthDataContext();


    let navigate = useNavigate();

    const [SubCategorylist, setSubCategorylist] = useState([]);  
    const [deleteRowStatus, setDeleteRowStatus] = useState(false);  

    const [showDeleteModal, setShowDeleteModal] = useState(false); 

    const [rowId, setRowId] = useState();
    const {setUpdateSubcategoryId}=useAuthDataContext();
    

    // useEffect(() => {

    //     axios
    //         .get("http://localhost:4889/api/get-all-subCategory")
    //         .then((response) => {


    //             setSubCategorylist(response.data.result);

    //         })
    //         .catch((error) => console.log(error));

    // }, []);
    const fnGetAllMedicine =()=>{
        const config = {
            headers: { Authorization: `Bearer ${iDtoken}` }
        };
       
        axios
        .get(api.baseUrl+"api/get-all-subCategory",config)
        .then((response) => {
    
    
            setSubCategorylist(response.data.result);
    
        })
        .catch((error) => console.log(error));
    }

    useEffect(() => {

        fnGetAllMedicine();
      

    }, []);
useEffect(() => {

    if(deleteRowStatus ===true) {
        const config = {
            headers: { Authorization: `Bearer ${iDtoken}` }
        };

       
        axios
        .get(api.baseUrl+`api/delete-one-subcategory/${rowId}`,config)
        .then((response) => {

          fnGetAllMedicine();

           setDeleteRowStatus(false);
           setShowDeleteModal(false);

        })
        .catch((error) => console.log(error));
    }
 

}, [deleteRowStatus]);

  
  const handledeleteclick =(_id)=>{
    setRowId(_id);
    setShowDeleteModal(true);

  }
      
  const handleUpdate =(_id)=>{
    setUpdateSubcategoryId(_id);
    navigate("/updateSubCategory")
  

  }


    return (
<>
            <div class="breadcome-area">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="breadcome-list">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div class="breadcomb-wp">
                                            <div class="breadcomb-icon">
                                                <i class="icon nalika-home"></i>
                                            </div>
                                            <div class="breadcomb-ctn">
                                                <h2>Sub Category List</h2>
                                                <p>Welcome to Glizmed <span class="bread-ntd">Admin Panel</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div class="breadcomb-report">
                                            <button data-toggle="tooltip" data-placement="left" title="Download Report" class="btn"><i class="icon nalika-download"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-fluid">
                    <div class="product-status ">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="product-status-wrap">
                                    <h4>Medicine List</h4>
                                    <div class="add-product">
                                        <a href="addmedicine.html">Add Medicine</a>
                                    </div>
                                    <table >
                                <tr >
                                    <th>Image</th>
                                    <th>Sub Category Name</th>
                                    <th>Delete</th>
                                    
                                </tr>
                                {SubCategorylist.map(value => (
                                    <tr>
                                        
                                    {/* <td>{medicinelist.ProductID}</td> */}
                                    <td>{value.imagesArr}</td>
                                    <td>{value.subCategoryName}</td>
                                    <button className="btn-delete-fnt" onClick={()=> handledeleteclick(value._id)}><i class="fa fa-trash" aria-hidden="true"></i></button>
                                    <button onClick={()=> handleUpdate(value._id)}>Edit</button>
                       
                                    </tr>
            
                                ))}
            
                            </table>
                                    {/* <ReactPaginate className="pagination"
                                previousLabel={"previous"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-item"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-item"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-item"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-item"}
                                activeClassName={"activepagination"}
                            />  */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
                


    <DeleteConfirmationModal
show={showDeleteModal}
setShow={setShowDeleteModal}
setDeleteRowStatus={setDeleteRowStatus} 
 />


</>



     
            
    );
}



export default SubCategoryList;
